<script setup>
defineProps({
  content: {
    type: String,
    required: true,
  },
})

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  if (!match && url.length === 11)
    return url

  return (match && match[2].length === 11)
    ? match[2]
    : null
}
</script>

<template>
  <div class="aspect-video overflow-hidden rounded-lg">
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      frameborder="0"
      height="100%"
      :src="`//www.youtube.com/embed/${getId(content)}`"
      title="YouTube video player"
      width="100%"
    />
  </div>
</template>
